import PropTypes from "prop-types";
import { useMemo } from "react";
// material
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import shape from "./shape";
import typography from "./typography";
import GlobalStyles from "./globalStyles";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import { useStore } from "../store/Store";
// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default function ThemeConfig({ children }) {

  const [store] = useStore();

  const themeOptionsWoColors = useMemo(
    () => ({
      shape: shape,
      typography: typography,
      shadows: shadows,
      customShadows: customShadows,

    }),
    []
  );
  const themeOptionsWithColors = useMemo(
    () => ({
      ...themeOptionsWoColors,
      palette: store.palette,
    }),
    // eslint-disable-next-line
    [store.palette]
  );


  const theme = createTheme(themeOptionsWithColors);
  theme.components = componentsOverride(theme);

  return (
    <>
      <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}
