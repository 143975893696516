import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/system";
import { Box, Button, Typography, Container } from "@mui/material";
// components
import Page from "../components/Page";
import { useTranslation } from "react-i18next";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100vh",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  padding: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function WentWrong() {
  const { t } = useTranslation();

  return (
    <RootStyle title={t("error")}>
      <Container maxWidth="sm">
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h3" textAlign={"center"}>
            {t("went-wrong")}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {t("sorry-went-wrong")}
          </Typography>

          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/assets/illustration_wentwrong.jpg`}
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 5 } }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              mt: 3,
            }}
          >
            <Button
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
              startIcon={<HomeIcon />}
            >
              {t("go-to-home")}
            </Button>
            <Button
              size="large"
              variant="contained"
              href="mailto:furkan.unal@smarttel.co"
              startIcon={<MailIcon />}
              sx={{
                backgroundColor: "greenyellow" //FTODO change later , get color from palette
              }}
            >
              {t("contact-support")}
            </Button>
          </Box>
        </Box>
      </Container>
    </RootStyle>
  );
}
