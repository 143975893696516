import React from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useStore } from "../store/Store";
import { useNavigate } from "react-router-dom";

export default function Bet() {
  const [store, dispatch] = useStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    const admin = store.admin;
    dispatch({ type: "PLATFORM_LOGOUT" });
    navigate(admin ? "/admin/login" : "/login", { replace: true });
  };

  return (
    
    <Card sx={{ maxWidth: "90%", margin:"auto", marginTop: 5 , padding: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Welcome to the Testing Page of SBS
        </Typography>
        <Typography variant="body1" gutterBottom>
          This page serves as a testing ground for exploring color palettes,
          button styles, and other design elements.
        </Typography>

        {/* Example partitions */}
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: 2 }}>
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Example 1: Button Styling
            </Typography>
            <Typography variant="body2">
              Explore different button variants and colors to see how they fit
              into your design scheme.
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Example 2: Color Palettes
            </Typography>
            <Typography variant="body2">
              Experiment with different color palettes to find the right
              combination for your application.
            </Typography>
          </Box>
 
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Example 3: Layout and Components
            </Typography>
            <Typography variant="body2">
              Test different layouts and component arrangements to optimize user
              experience.
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Example 4: Accessibility
            </Typography>
            <Typography variant="body2">
              Ensure your application meets accessibility standards.
            </Typography>
          </Box>
        </Box>

        {/* Logout Button */}
        <Box sx={{ marginTop: 3 }}>
          <Button
            variant="contained"
            color="accent"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
