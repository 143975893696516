import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// ----------------------------------------------------------------------
import TRANSLATIONS_EN from "./translations/en.json";
import TRANSLATIONS_DE from "./translations/de.json";
import TRANSLATIONS_FR from "./translations/fr.json";
import TRANSLATIONS_IT from "./translations/it.json";
import TRANSLATIONS_TR from "./translations/tr.json";
import TRANSLATIONS_AR from "./translations/ar.json";
import TRANSLATIONS_ES from "./translations/es.json";
import TRANSLATIONS_HI from "./translations/hi.json";
import TRANSLATIONS_ID from "./translations/id.json";
import TRANSLATIONS_JA from "./translations/ja.json";
import TRANSLATIONS_PT from "./translations/pt.json";
import TRANSLATIONS_ZH from "./translations/zh.json";
import TRANSLATIONS_DA from "./translations/da.json";
import TRANSLATIONS_FI from "./translations/fi.json";
import TRANSLATIONS_SV from "./translations/sv.json";
// ----------------------------------------------------------------------

const resources = {
    "en-US": { translation: TRANSLATIONS_EN },
    "de-DE": { translation: TRANSLATIONS_DE },
    "fr-FR": { translation: TRANSLATIONS_FR },
    "it-IT": { translation: TRANSLATIONS_IT },
    "tr-TR": { translation: TRANSLATIONS_TR },
    "ar-AR": { translation: TRANSLATIONS_AR },
    "es-ES": { translation: TRANSLATIONS_ES },
    "hi-HI": { translation: TRANSLATIONS_HI },
    "id-ID": { translation: TRANSLATIONS_ID },
    "ja-JA": { translation: TRANSLATIONS_JA },
    "pt-PT": { translation: TRANSLATIONS_PT },
    "zh-ZH": { translation: TRANSLATIONS_ZH },
    "da-DA": { translation: TRANSLATIONS_DA },
    "fi-FI": { translation: TRANSLATIONS_FI },
    "sv-SV": { translation: TRANSLATIONS_SV },
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        lng: localStorage.getItem("language") || "en-US",
        fallbackLng: "en-US",
    });

export default i18n;
