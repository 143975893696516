import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/system';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.lightmost,
  padding: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const { t } = useTranslation();
  return (
    <RootStyle title={t('page-not-found')}>
      <Container maxWidth="sm">
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h3" textAlign={"center"}>
            {t('sorry-page-not-found')}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {t('sorry-we-couldnt-find-the-page-youre-looking-for-perhaps-youve-mistyped-the-url-be-sure-to-check-your-spelling')}
          </Typography>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/assets/illustration_404.svg`}
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />
          <Button to="/" size="large" variant="contained" component={RouterLink}>
            {t('go-to-home')}
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
