import React from "react";
import { useState, useEffect } from "react";
import { Tooltip, IconButton, Typography, Card, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DefaultPaginationData } from "../../../constants";
import BaseModal from "../../../components/BaseModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import { rowArrayToObject } from "../../../utils/Util";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { PartitionsService } from "../../../api/services";
import SearchBar from "../../../components/SearchBar";
import { useTheme } from "@mui/material/styles";
import AddNewButton from "../../../components/buttons/AddNewButton";
import { useNavigate, useLocation } from "react-router-dom";
import EditPartition from "./editPartition/EditPartition";

export default function Home() {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const { successMessage } = location.state || {};
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [openEditModal, setEditModalStatus] = useState(false);
  const [openDeleteModal, setDeleteModalStatus] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (successMessage) {
      setSnackbarStatus(true);
      setMessage(successMessage);
    }
  }, [successMessage]);

  const TABLE_HEAD = [
    { key: "id", label: t("partition-id") },
    { key: "name", label: t("partition") },
    { key: "fqdn", label: t("fqdn"), align: "center" },
    {
      key: "distributor_count",
      label: t("distributor-count"),
      align: "center",
    },
    { key: "sports", label: t("sports"), align: "center"},
    { key: "bookmakers", label: t("bookmakers"), align: "center" },
    { key: "currency", label: t("currency"), align: "center" },
    { key: "action", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    name: { key: "name", index: 1 },
    fqdn: { key: "fqdn", index: 2, align: "center" },
    distributor_count: { key: "distributor_count", index: 3, align: "center" },
    sports: { key: "sports", index: 4, align: "center" },
    bookmakers: { key: "bookmakers", index: 5, align: "center" },
    currency: { key: "currency", index: 6, align: "center" },
    currency_id: { key: "currency_id", index: 7, noRender: true },
    partition_sports: { key: "partition_sports", index: 8, noRender: true },
    partition_bookmakers: { key: "partition_bookmakers", index: 9, noRender: true },
  };

  const fetchPartitions = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

    PartitionsService.listPartitions(params)
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          if (itemData.fqdn) {
            let fqdnName = itemData.fqdn[itemData.fqdn.length - 1]; //Last element of fqdn array
            item[TABLE_FIELD_MAPPING["fqdn"].index] = {
              ...TABLE_FIELD_MAPPING["fqdn"],
              value: fqdnName,
            };
          }
          item[TABLE_FIELD_MAPPING["distributor_count"].index] = {
            ...TABLE_FIELD_MAPPING["distributor_count"],
            value: 0,
          };

          const sportsList = itemData.partition_sports
            ?.filter((sport) => sport.is_active)
            .map((sportData, index) => (
              <Typography
                key={sportData.id}
                fontWeight={700}
                textAlign={"left"}
                sx={{ color: theme.palette.neutral.darkest }}
              >
                {index + 1} - {sportData.sport.name}
              </Typography>
            ));

          const bookmakersList = itemData.partition_bookmakers
            ?.filter((bookmaker) => bookmaker.is_active)
            .map((bookmakerData, index) => (
              <Typography
                key={bookmakerData.id}
                fontWeight={700}
                textAlign={"left"}
                sx={{ color: theme.palette.neutral.darkest }}
              >
                {index + 1} - {bookmakerData.bookmaker.name}
              </Typography>
            ));

          item[TABLE_FIELD_MAPPING["sports"].index] = {
            ...TABLE_FIELD_MAPPING["sports"],
            value: sportsList.length > 0 && (
              <Card
                sx={{
                  position: "relative",
                  pt: 1,
                  pb: 1,
                  minWidth: "130px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: 1,
                  overflow: "visible",
                }}
              >
                <Tooltip title={t("edit-active-sports")}>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() =>
                      navigate(
                        `/partitions/manage/active-sports/${itemData.id}`
                      )
                    }
                    sx={{
                      position: "absolute",
                      zIndex: "100",
                      top: "-12px",
                      right: "-12px",
                      backgroundColor: "white",
                      boxShadow: 1,
                      "&:hover": {
                        backgroundColor: "lightgrey",
                      },
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>

                <Box>{sportsList}</Box>
              </Card>
            ),
          };
          item[TABLE_FIELD_MAPPING["partition_sports"].index] = {
            ...TABLE_FIELD_MAPPING["partition_sports"],
            value: itemData.partition_sports,
          };

          item[TABLE_FIELD_MAPPING["partition_bookmakers"].index] = {
            ...TABLE_FIELD_MAPPING["partition_bookmakers"],
            value: itemData.partition_bookmakers,
          };

          item[TABLE_FIELD_MAPPING["bookmakers"].index] = {
            ...TABLE_FIELD_MAPPING["bookmakers"],
            value: bookmakersList.length > 0 && (
              <Card
                sx={{
                  position: "relative",
                  pt: 1,
                  pb: 1,
                  minWidth: "130px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: 1,
                  overflow: "visible",
                }}
              >
                <Tooltip title={t("edit-active-bookmakers")}>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() =>
                      navigate(
                        `/partitions/manage/active-bookmakers/${itemData.id}`
                      )
                    }
                    sx={{
                      position: "absolute",
                      zIndex: "100",
                      top: "-12px",
                      right: "-12px",
                      backgroundColor: "white",
                      boxShadow: 1,
                      "&:hover": {
                        backgroundColor: "lightgrey",
                      },
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
                <Box>{bookmakersList}</Box>
              </Card>
            ),
          };

          item[TABLE_FIELD_MAPPING["currency"].index] = {
            ...TABLE_FIELD_MAPPING["currency"],
            value: itemData.currency?.name + " " + itemData.currency?.symbol,
          };
          item[TABLE_FIELD_MAPPING["currency_id"].index] = {
            ...TABLE_FIELD_MAPPING["currency_id"],
            value: itemData.currency?.id,
          };

          Object.entries(itemData).forEach(([key, value]) => {
            if (
              key in TABLE_FIELD_MAPPING &&
              key !== "partition_sports" &&
              key !== "currency" &&
              key !== "fqdn" && 
              key !== "partition_bookmakers"
            ) {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
      })
      .catch((err) => {
        setMessage(t("failed-to-get-partitions"));
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchPartitions();
    // eslint-disable-next-line
  }, [paginationData]);


  const modalHandler = (modalType, index = undefined) => {
    switch (modalType) {
      case "edit":
        setEditModalStatus(!openEditModal);
        break;
      case "delete":
        setDeleteModalStatus(!openDeleteModal);
        break;
      default:
        break;
    }

    if (index) {
      setSelectedRow(rowArrayToObject(data[index]));
    }
  };

  const getActionItems = (index) => (
    <Box sx={{minWidth: "80px"}}>
      <Tooltip title={t("edit-partition")}>
        <IconButton
          sx={{
            color: theme.palette.neutral
          }}
          size="small"
          aria-label="edit-partition"
          onClick={() => modalHandler("edit", index)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("delete-partition")}>
        <IconButton
          color="secondary"
          size="small"
          aria-label="delete-partition"
          onClick={() => modalHandler("delete", index)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    return data.filter((row) => {
      return ["name", "fqdn", "currency"].some((key) => {
        const cellValue = row[TABLE_FIELD_MAPPING[key]?.index]?.value || "";
        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
      });
    });
  };

  const filteredData = filterData(data, searchQuery);

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <BaseModal
        title={t("edit-partition")+" - "+ selectedRow.name}
        sx={{
          width: "65%",
          height: "97%",
          overflow: "auto"
        }}
        open={openEditModal}
        setOpen={setEditModalStatus}
        children={
          <EditPartition
            successCallback={fetchPartitions}
            formData={selectedRow}
            setModalStatus={setEditModalStatus}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
          />
        }
      />
      <BaseModal
        title={t("delete-partition")}
        open={openDeleteModal}
        setOpen={setDeleteModalStatus}
        children={<>Delete Partition Form</>}
      />
      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
        />
        <AddNewButton
          sx={{ mb: 2 }}
          label={t("add-new-partition")}
          onClick={() => navigate("/partitions/add-new-partition")}
        />
      </TableFilterContainer>

      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
