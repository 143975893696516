import {
  MenuItem,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import i18n from "../i18n";

export const getSelectOptions = (
  options,
  discardItems = [],
  emptyText = i18n.t("any")
) => {
  let items = emptyText
    ? [
        <MenuItem key="empty" value="0">
          {emptyText}
        </MenuItem>,
      ]
    : [];
  options.forEach((data, idx) => {
    if (!discardItems.includes(data.value)) {
      items.push(
        <MenuItem key={idx} value={data.value}>
          {data.label}
        </MenuItem>
      );
    }
  });
  return items;
};

export const getRadioButtonOptions = (
  options,
  disabled = false,
  discardItems = []
) => {
  let items = [];
  options.forEach((data, idx) => {
    if (!discardItems.includes(data.value)) {
      items.push(
        <FormControlLabel
          disabled={disabled}
          key={`${data.value}-${idx}`}
          value={data.value}
          control={<Radio />}
          label={data.label}
        />
      );
    }
  });
  return items;
};


export const DefaultPaginationData = {
  page: 0,
  rowsPerPage: 10,
  totalCount: 0,
};
export const getRadioButtonComponent = (
  options,
  props,
  title,
  direction = "row",
  discardItems = []
) => {
  const disabled = props.disabled ? true : false;
  return (
    <FormControl sx={{ textAlign: "left" }} component="fieldset">
      <FormLabel disabled={disabled} color="secondary">
        {title}
      </FormLabel>
      <RadioGroup {...props} row={direction === "row" ? true : false}>
        {getRadioButtonOptions(options, disabled, discardItems)}
      </RadioGroup>
    </FormControl>
  );
};

export const getItemDetails = (options, key = "value") => {
  let items = [];
  options.forEach((data, idx) => {
    items.push(options[key]);
  });
  return items;
};

export const getValueByLabel = (options, label) => {
  for (const idx in options) {
    if (options[idx].label === label) {
      return options[idx].value;
    }
  }
};

export const getLabelByValue = (options, value) => {
  for (const idx in options) {
    if (options[idx].value === value) {
      return options[idx].label;
    }
  }
};
