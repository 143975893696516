import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormikProvider, useFormik } from "formik";
import { CommonService, PartitionsService } from "../../../../api/services";
import BaseSnackbar from "../../../../components/BaseSnackbar";
import { isMobile } from "../../../../utils/Util";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function AddPartition() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [sports, setSports] = useState([]);
  const [activeSports, setActiveSports] = useState([]);
  const [bookmakers, setBookmakers] = useState([]);
  const [activeBookmakers, setActiveBookmakers] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [searchSportQuery, setSearchSportQuery] = useState("");
  const [selectAllSports, setSelectAllSports] = useState(false);
  const [searchBookmakerQuery, setSearchBookmakerQuery] = useState("");
  const [selectAllBookmakers, setSelectAllBookmakers] = useState(false);
  const [addDnsRecord, setAddDnsRecord] = useState(false);
  const searchActive = true; //For testing

  const PartitionSchema = Yup.object().shape({
    partitionName: Yup.string()
      .required(t("partition-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    partitionID: Yup.number().required(t("partition-id-is-required")),
    fqdn: Yup.string().required(t("fqdn-is-required")),
    currency: Yup.number().required(t("currency-is-required")),
  });

  const formik = useFormik({
    validationSchema: PartitionSchema,
    initialValues: {
      partitionName: "",
      partitionID: "",
      fqdn: "",
      currency: "",
    },

    onSubmit: (values, actions) => {
      const payload = {
        id: values.partitionID,
        name: values.partitionName,
        fqdn: [values.fqdn],
        currency_id: values.currency,
        active_sports: activeSports,
        active_bookmakers: activeBookmakers,
      };

      let successMessage = t("new-partition-added-succesfully");
      let failMessage = t("partition-add-fail");

      PartitionsService.createPartition(
        { add_dns_record: addDnsRecord },
        payload
      )
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setTimeout(() => {
              navigate("/partitions/list", {
                state: {
                  successMessage: successMessage,
                },
              });
            }, 600);

            actions.setSubmitting(false);
          } else {
            throw new Error("failed to create partition");
          }
        })
        .catch((err) => {
          console.error(err);
          setMessage(failMessage);
          setSnackbarStatus(true);
          setTimeout(function () {
            actions.setSubmitting(false);
          }, 1000);
        });
    },
  });

  useEffect(() => {
    CommonService.listCurrencies()
      .then((response) => {
        if (response.status === 200) {
          let currencyOptions = response.data.map((currency) => ({
            value: currency.id,
            label: currency.name,
          }));
          setCurrencies(currencyOptions);
        } else {
          throw new Error("failed to get currencies");
        }
      })
      .catch((err) => {
        console.error(err);
      });

    CommonService.listSports()
      .then((response) => {
        if (response.status === 200) {
          setSports(response.data);
        } else {
          throw new Error("failed to get sports");
        }
      })
      .catch((err) => {
        console.error(err);
      });

    CommonService.listBookmakers()
      .then((response) => {
        if (response.status === 200) {
          setBookmakers(response.data);
        } else {
          throw new Error("failed to get bookmakers");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (selectAllSports) {
      let allSportIds = sports.map((sport) => sport.id);
      setActiveSports(allSportIds);
    } else {
      if (activeSports.length === sports.length) {
        setActiveSports([]);
      }
    }
    // eslint-disable-next-line
  }, [selectAllSports]);

  useEffect(() => {
    if (activeSports.length === sports.length && sports.length !== 0) {
      setSelectAllSports(true);
    } else {
      setSelectAllSports(false);
    }
    // eslint-disable-next-line
  }, [activeSports]);

  useEffect(() => {
    if (selectAllBookmakers) {
      let allBookmakerIDs = bookmakers.map((bookmaker) => bookmaker.id);
      setActiveBookmakers(allBookmakerIDs);
    } else {
      if (activeBookmakers.length === bookmakers.length) {
        setActiveBookmakers([]);
      }
    }
    // eslint-disable-next-line
  }, [selectAllBookmakers]);

  useEffect(() => {
    if (
      activeBookmakers.length === bookmakers.length &&
      bookmakers.length !== 0
    ) {
      setSelectAllBookmakers(true);
    } else {
      setSelectAllBookmakers(false);
    }
    // eslint-disable-next-line
  }, [activeBookmakers]);

  const renderSports = () => {
    return sports
      .filter((sport) =>
        sport.name.toLowerCase().includes(searchSportQuery.toLowerCase())
      )
      .map((sport) => (
        <TableRow key={sport.id}>
          <TableCell size="small">{sport.name}</TableCell>
          <TableCell size="small" align="right" sx={{ paddingRight: "3em" }}>
            <Checkbox
              checked={activeSports.includes(sport.id)}
              onChange={() => handleSportToggle(sport)}
            />
          </TableCell>
        </TableRow>
      ));
  };

  const renderBookmakers = () => {
    return bookmakers
      .filter((bookmaker) =>
        bookmaker.name
          .toLowerCase()
          .includes(searchBookmakerQuery.toLowerCase())
      )
      .map((bookmaker) => (
        <TableRow key={bookmaker.id}>
          <TableCell size="small">{bookmaker.name}</TableCell>
          <TableCell size="small" align="right" sx={{ paddingRight: "3em" }}>
            <Checkbox
              checked={activeBookmakers.includes(bookmaker.id)}
              onChange={() => handleBookmakerToggle(bookmaker)}
            />
          </TableCell>
        </TableRow>
      ));
  };

  const handleSportToggle = (sport) => {
    if (activeSports.includes(sport.id)) {
      setActiveSports(activeSports.filter((sportID) => sportID !== sport.id));
    } else {
      setActiveSports([...activeSports, sport.id]);
    }
  };

  const handleBookmakerToggle = (bookmaker) => {
    if (activeBookmakers.includes(bookmaker.id)) {
      setActiveBookmakers(
        activeBookmakers.filter((bookmakerID) => bookmakerID !== bookmaker.id)
      );
    } else {
      setActiveBookmakers([...activeBookmakers, bookmaker.id]);
    }
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 5,
              gap: 4,
              mt: 5,
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: "row",
              alignItems: 'center',
            }}>
              <Typography variant="h6s_extraBold">{t('add-dns-record')}:</Typography>
              <Switch
                checked={addDnsRecord}
                onChange={(e) => setAddDnsRecord(e.target.checked)}
              />
            </Box>
            <Grid container spacing={10}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!isMobile() && (
                  <InputLabel>
                    {t("partition-name")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                )}
                <TextField
                  fullWidth
                  id="partitioName"
                  label={isMobile() ? t("partition-name") : undefined}
                  placeholder={t("enter-partition-name")}
                  {...getFieldProps("partitionName")}
                  error={Boolean(touched.partitionName && errors.partitionName)}
                  helperText={touched.partitionName && errors.partitionName}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!isMobile() && (
                  <InputLabel>
                    {t("partition-id")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                )}
                <TextField
                  fullWidth
                  id="partitionID"
                  label={isMobile() ? t("partition-id") : undefined}
                  placeholder={t("enter-partition-id")}
                  {...getFieldProps("partitionID")}
                  error={Boolean(touched.partitionID && errors.partitionID)}
                  helperText={touched.partitionID && errors.partitionID}
                />
              </Grid>
            </Grid>
            <Grid container spacing={10}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!isMobile() && (
                  <InputLabel>
                    {t("partition-fqdn")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                )}
                <TextField
                  fullWidth
                  id="partitionFqdn"
                  label={isMobile() ? t("partition-fqdn") : undefined}
                  placeholder={t("enter-partition-fqdn")}
                  {...getFieldProps("fqdn")}
                  error={Boolean(touched.fqdn && errors.fqdn)}
                  helperText={touched.fqdn && errors.fqdn}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!isMobile() && (
                  <InputLabel>
                    {t("partition-currency")}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                )}
                <Autocomplete
                  id="combo-box-demo"
                  options={currencies}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={!selectedCurrency}
                      label={t("select-currency")}
                      error={Boolean(touched.currency && errors.currency)}
                      helperText={touched.currency && errors.currency}
                      onBlur={() => formik.setFieldTouched("currency", true)}
                    />
                  )}
                  value={
                    currencies.find(
                      (option) => option.value === selectedCurrency
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    const currencyValue = newValue
                      ? Number(newValue.value)
                      : null;
                    setSelectedCurrency(currencyValue);
                    formik.setFieldValue("currency", currencyValue);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputLabel
                  sx={{
                    textAlign: "center",
                    fontWeight: 600,
                    fontSize: 18,
                    color: theme.palette.neutral.darker,
                  }}
                >
                  {t("active-sports")}
                </InputLabel>
                <Divider />

                <Card
                  sx={{
                    minHeight: "30vh",
                  }}
                >
                  <CardContent>
                    {searchActive && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <TextField
                          placeholder={t("search-sport")}
                          size="small"
                          variant="standard"
                          value={searchSportQuery}
                          onChange={(e) => setSearchSportQuery(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ border: "none" }}
                              >
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    )}
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("sport-name")}</TableCell>
                            <TableCell align="right">
                              {t("select")}{" "}
                              <Checkbox
                                style={{
                                  transform: "scale(0.7)",
                                  marginLeft: -10,
                                }}
                                checked={selectAllSports}
                                onChange={(e) =>
                                  setSelectAllSports(e.target.checked)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>{renderSports()}</TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputLabel
                  sx={{
                    textAlign: "center",
                    fontWeight: 600,
                    fontSize: 18,
                    color: theme.palette.neutral.darker,
                  }}
                >
                  {t("active-bookmakers")}
                </InputLabel>
                <Divider />
                <Card
                  sx={{
                    minHeight: "30vh",
                  }}
                >
                  <CardContent>
                    {searchActive && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <TextField
                          placeholder={t("search-bookmaker")}
                          size="small"
                          variant="standard"
                          value={searchBookmakerQuery}
                          onChange={(e) =>
                            setSearchBookmakerQuery(e.target.value)
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ border: "none" }}
                              >
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    )}
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow> 
                            <TableCell>{t("bookmaker-name")}</TableCell>
                            <TableCell align="right">
                              {t("select")}{" "}
                              <Checkbox
                                style={{
                                  transform: "scale(0.7)",
                                  marginLeft: -10,
                                }}
                                checked={selectAllBookmakers}
                                onChange={(e) =>
                                  setSelectAllBookmakers(e.target.checked)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>{renderBookmakers()}</TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Box
              className="add-partition-button"
              sx={{
                width: "20%",
                alignSelf: "center",
              }}
            >
              <LoadingButton
                sx={{ mt: 3, backgroundColor: theme.palette.accent.main }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                startIcon={<SaveIcon />}
              >
                {t("add-partition")}
              </LoadingButton>
            </Box>
          </Card>
        </Form>
      </FormikProvider>
    </>
  );
}
