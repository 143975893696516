import { Box } from "@mui/material";
import PropTypes from "prop-types";
// material

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  const src = `${process.env.PUBLIC_URL}/assets/logo.png`;

  return (
    <>
        {/* IMPLEMENT LOGO LATER */}
      <Box component="img" src={src} sx={{ width: 40, height: 40, ...sx }} />

    </>
  );
}
