import api from "../Api"
import urls from "../Urls";

export const listPartitions = (params) => {
    return api.get(urls.list_partitions, {params: params})
}

export const getPartition = (id) => {
    return api.get(urls.get_partition(id) )
}


export const createPartition = (params, payload) => {
    return api.post(urls.create_partition, payload, {
        params: params
    });
};



export const updatePartition = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_partition(id), payload, {params})
}



export const getSportBookmakerMappings = (id) => {
    return api.get(urls.get_sport_bookmaker_mappings(id))
}

export const updateSportBookmakerMapping = (id, payload) => {
    return api.put(urls.update_sport_bookmaker_mapping(id), payload)
}