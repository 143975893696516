import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SummarizeIcon from "@mui/icons-material/Summarize";
import GroupIcon from "@mui/icons-material/Group";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BroadcastOnHomeIcon from '@mui/icons-material/BroadcastOnHome';
import i18n from "../../i18n";

// ----------------------------------------------------------------------


export const platformPagesConfig = () => [
  // If a parent path has child routes, the parent path should not navigate to a page directly.
  // Instead, it should navigate to the first child route. This can be implemented in router.js like this:
  // { path: "/admin/partitions", element: <Navigate to="/admin/partitions/list" /> },
  // { path: "/admin/partitions/list", element: <Home /> },

  //Partitions
  {
    title: i18n.t("partitions"),
    path: "/partitions/",
    icon: <BusinessIcon />,
    adminOnly: true,
    children: [
      {
        title: i18n.t("list-partitions"),
        path: "/partitions/list",
        adminOnly: true,
        icon: <GroupIcon />,
      },
      {
        title: i18n.t("manage-partition"),
        path: "/partitions/manage",
        adminOnly: true,
        icon: <ManageAccountsIcon />,
      },
      {
        title: i18n.t("add-partition"),
        path: "/partitions/add-new-partition",
        adminOnly: true,
        icon: <AddBusinessIcon />,
      },
    ],
  },

  //Reports
  {
    title: i18n.t("reports"),
    path: "/reports",
    adminOnly: true,
    icon: <SummarizeIcon />,
    children: [
      {
        title: i18n.t("partition-reports"),
        path: "/reports/partition-reports",
        adminOnly: true,
        icon: <CardMembershipIcon />,
      },
      {
        title: i18n.t("distributor-reports"),
        path: "/reports/distributor-reports",
        adminOnly: true,
        icon: <CardMembershipIcon />,
      },
    ],
  },
    //TEST
    {
      title: i18n.t("test"),
      path: "/test",
      adminOnly: true,
      icon: <SummarizeIcon />
    }
];

export const partitionPagesConfig = () => [

  //Partitions

  {
    title: i18n.t("dashboard"),
    path: "/landing",
    icon: <DashboardIcon />
  },
  {
    title: i18n.t("Distributor"),
    path: "/distributor/",
    icon: <BroadcastOnHomeIcon />,
    children: [
      {
        title: i18n.t("list-distributors"),
        path: "/distributor/list",
        icon: <GroupIcon />,
      },
      {
        title: i18n.t("add-distributor"),
        path: "/distributor/add-new-distributor",
        icon: <AddBusinessIcon />,
      },
    ]
  },
  {
    title: i18n.t("Partition Users"),
    path: "/partition-users/",
    icon: <GroupIcon />,
    children: [
      {
        title: i18n.t("list-partition-users"),
        path: "/partition-users/list-users",
        icon: <GroupIcon />,
      },
      {
        title: i18n.t("add-partition-users"),
        path: "/partition-users/add-new-user",
        icon: <GroupIcon />,
      },
    ]
  },
];
export const distributorPagesConfig = () => [

  //Distributors
  {
    title: i18n.t("dashboard"),
    path: "/dt/landing",
    icon: <DashboardIcon />
  },
  {
    title: i18n.t("Distributor Users"),
    path: "/dt/distributor-users/",
    icon: <GroupIcon />,
    children: [
      {
        title: i18n.t("list-distributor-users"),
        path: "/dt/distributor-users/list-users",
        icon: <GroupIcon />,
      },
      {
        title: i18n.t("add-distributor-users"),
        path: "/dt/distributor-users/add-new-user",
        icon: <GroupIcon />,
      },
    ]
  },
];
export const storePagesConfig = () => [

  //Store
  {
    title: i18n.t("dashboard"),
    path: "/st/landing",
    icon: <DashboardIcon />
  },
  {
    title: i18n.t("Store Users"),
    path: "/st/store-users/",
    icon: <GroupIcon />,
    children: [
      {
        title: i18n.t("list-store-users"),
        path: "/st/store-users/list-users",
        icon: <GroupIcon />,
      },
      {
        title: i18n.t("add-store-users"),
        path: "/st/store-users/add-new-user",
        icon: <GroupIcon />,
      },
    ]
  },
];

export const kioskPagesConfig = () => [
  
  //Kiosk
  {
    title: i18n.t("dashboard"),
    path: "/ki/landing",
    icon: <DashboardIcon />
  }
];
