import { useState, useEffect } from "react";
import { PartitionsService, CommonService } from "../../../../api/services";
import {
  Autocomplete,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SaveIcon from "@mui/icons-material/Save";
import BaseSnackbar from "../../../../components/BaseSnackbar";
import { BaseTable } from "../../../../components/table";
import { styled } from "@mui/system";
import { rowArrayToObject } from "../../../../utils/Util";


const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: "3vh",
  backgroundColor: theme.palette.neutral.light,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

export default function SportBookmakerMap({ partition, selectedTab }) {
  const { t } = useTranslation();
  const [bookmakers, setBookmakers] = useState([]);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [partitions, setPartitions] = useState([]);

  const TABLE_HEAD = [
    { key: "sport", label: t("sport") },
    { key: "bookmaker_select", label: t("bookmaker") },
    { key: "actions", label: t("actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", index: 0, cellComponentType: "th", noRender: true },
    sport: { key: "sport", index: 1 },
    bookmaker_id: { key: "bookmaker_select", index: 2 },
    sport_id: { key: "sport_id", index: 3, noRender: true },
    bookmaker_name: { key: "bookmaker_name", index: 4, noRender: true },
  };

  const fetchData = () => {
    if (partition) {
      setLoadingData(true);
      PartitionsService.getSportBookmakerMappings(partition.value) 
      .then((response) => {
        if (response.status === 200) {
          let items = [];
          for (const mapping of response.data) {
            let item = new Array(TABLE_HEAD.length - 1).fill({});

            item[TABLE_FIELD_MAPPING["id"].index] = {
              ...TABLE_FIELD_MAPPING["id"],
              value: mapping.id,
            };
            if (mapping.sport) {
              let sportName = mapping.sport?.name;
              item[TABLE_FIELD_MAPPING["sport"].index] = {
                ...TABLE_FIELD_MAPPING["sport"],
                value: sportName,
              };
              let sportID = mapping.sport?.id;
              item[TABLE_FIELD_MAPPING["sport_id"].index] = {
                ...TABLE_FIELD_MAPPING["sport_id"],
                value: sportID,
              };
            }

            let bookmakerName = mapping.bookmaker?.name;
            item[TABLE_FIELD_MAPPING["bookmaker_name"].index] = {
              ...TABLE_FIELD_MAPPING["bookmaker_name"],
              value: bookmakerName ?? "",
            };
            let bookmaker_id = mapping.bookmaker?.id;
            item[TABLE_FIELD_MAPPING["bookmaker_id"].index] = {
              ...TABLE_FIELD_MAPPING["bookmaker_id"],
              value: bookmaker_id ?? 0,
            };

            items.push(item);
          }
          setData(items);
          setLoadingData(false);
          const deepCopy = JSON.parse(JSON.stringify(items));
          setInitialData(deepCopy);
        } else {
          throw new Error("Get sport bookmaker mapping failed");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

useEffect(() => {
  CommonService.listBookmakers()
    .then((response) => {
      if (response.status === 200) {
        let options = response.data.map((bookmaker) => ({
          value: bookmaker.id,
          label: bookmaker.name,
        }));
        setBookmakers(options);
      } else {
        throw new Error("List bookmakers failed");
      }
    })
    .catch((err) => {
      console.error(err);
    });
}, []);

useEffect(() => {
  if (partition && selectedTab === 2) {
    fetchData();
  }
}, [partition, selectedTab]);

  const bookmakerSelect = (index) => {
    let selectedValue = rowArrayToObject(data[index]).bookmaker_select;
    const selectableBookmakers = partition.bookmakers
      .filter((bookmaker) => bookmaker.is_active)
      .map((item) => item.bookmaker.id);

    return (
      <FormControl sx={{ width: "50%" }}>
        <Autocomplete
          id="combo-box-demo"
          options={bookmakers}
          getOptionDisabled={(option) =>
            !selectableBookmakers.includes(option.value)
          }
          getOptionLabel={(option) => {
            const isDisabled = !selectableBookmakers.includes(option.value);
            return `${option.label}${isDisabled ? " " + t("inactive-bookmaker") : ""}`;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={selectedValue ? t("bookmaker") : t("select-bookmaker")}
            />
          )}
          value={
            bookmakers.find((option) => option.value === selectedValue) || null
          }
          onChange={(event, newValue) => {
            if (newValue && newValue.value) {
              handleBookmakerChange(index, newValue);
            } else {
              handleBookmakerChange(index, null);
            }
          }}
        />
      </FormControl>
    );
  };

  const handleBookmakerChange = (index, selectedBookmaker) => {
    let copiedData = [...data];
    copiedData[index][2].value = selectedBookmaker?.value || null;
    setData(copiedData);
  };

  const handleSave = (index) => {
    let payload = {
      mappings: [
        {
          sport_id: data[index][3].value,
          bookmaker_id: data[index][2].value,
        },
      ],
    };

    let successMessage = t("sport-bookmaker-map-set-success");
    let failMessage = t("failed-to-set-sport-bookmaker-map");

    PartitionsService.updateSportBookmakerMapping(partition.value, payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(successMessage);
          setSnackbarStatus(true);
          const newData = [...initialData];
          newData[index] = JSON.parse(JSON.stringify(data))[index];
          setInitialData(newData);
        } else {
          throw new Error("Failed to update sport bookmaker mapping");
        }
      })
      .catch((err) => {
        if (err.response?.data) {
          failMessage = `${failMessage}. ${err.response.data[0]}`;
        }
        setMessage(failMessage);
        setSnackbarStatus(true);
      });
  };

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i].value) !== JSON.stringify(arr2[i].value)) {
        return false;
      }
    }
    return true;
  };

  const getActionItems = (index) => {
    const isDisabled =
      initialData.length === data.length &&
      arraysEqual(data[index], initialData[index]);

    return (
      <Tooltip title={t("save-changes")}>
        <span>
          <IconButton
            disabled={isDisabled}
            color="secondary"
            size="small"
            aria-label="edit-package"
            onClick={() => handleSave(index)}
          >
            <SaveIcon />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      {partition ? (
        <BaseTable
          head={TABLE_HEAD}
          data={data}
          pagination={{}}
          loadingData={loadingData}
          bookmakerSelect={bookmakerSelect}
          actionItemPrep={getActionItems}
        />
      ) : (
        <StyledBox>Please select partition</StyledBox>
      )}
    </>
  );
}
