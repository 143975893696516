function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ xs, sm, md, lg }) {
  return {
    "@media (max-width:600px)": {
      fontSize: pxToRem(xs),
    },
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:960px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1280px)": {
      fontSize: pxToRem(lg),
    },
  };
}

const d1 = {
  lineHeight: pxToRem(80),
  letterSpacing: "-0.04em",
  color: "#0D0F11",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 56, sm: 64, md: 72, lg: 72 }),
};

const d2 = {
  lineHeight: pxToRem(72),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 44, sm: 52, md: 60, lg: 60 }),
};

const h1 = {
  lineHeight: pxToRem(56),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 32, sm: 40, md: 48, lg: 48 }),
};

const h1s = {
  lineHeight: pxToRem(40),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 18, sm: 26, md: 34, lg: 34 }),
};

const h2 = {
  lineHeight: pxToRem(47),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 23, sm: 31, md: 39, lg: 39 }),
};

const h2s = {
  lineHeight: pxToRem(40),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 17, sm: 25, md: 33, lg: 33 }),
};

const h3 = {
  lineHeight: pxToRem(40),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 21, sm: 28, md: 33, lg: 33 }),
};

const h3s = {
  lineHeight: pxToRem(34),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 14, sm: 21, md: 28, lg: 28 }),
};

const h4 = {
  lineHeight: pxToRem(34),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 18, sm: 23, md: 28, lg: 28 }),
};

const h4s = {
  lineHeight: pxToRem(28),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 13, sm: 18, md: 23, lg: 23 }),
};

const h5 = {
  lineHeight: pxToRem(28),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 13, sm: 18, md: 23, lg: 23 }),
};

const h5s = {
  lineHeight: pxToRem(23),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 12, sm: 14, md: 19, lg: 19 }),
};

const h6 = {
  lineHeight: pxToRem(28),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 13, sm: 18, md: 23, lg: 23 }),
};

const h6s = {
  lineHeight: pxToRem(19),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 12, sm: 14, md: 19, lg: 19 }),
};

const subHeading = {
  lineHeight: pxToRem(24),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 13, sm: 16, md: 20, lg: 20 }),
};

const p1 = {
  lineHeight: pxToRem(17),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 9, sm: 11, md: 14, lg: 14 }),
};

const p2 = {
  lineHeight: pxToRem(19),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 10, sm: 13, md: 16, lg: 16 }),
};

const p3 = {
  lineHeight: pxToRem(21.6),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 12, sm: 15, md: 18, lg: 18 }),
};

const caption = {
  lineHeight: pxToRem(14),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 8, sm: 10, md: 12, lg: 12 }),
};

const footer = {
  lineHeight: pxToRem(12),
  color: "#0D0F11",
  letterSpacing: "-0.04em",
  textAlign: "left",
  ...responsiveFontSizes({ xs: 8, sm: 8, md: 10, lg: 10 }),
};

const FONT_PRIMARY = "Lato";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,

  d1: { ...d1 },
  d1_semiBold: { fontWeight: 600, ...d1 },
  d1_bold: { fontWeight: 700, ...d1 },
  d1_extraBold: { fontWeight: 800, ...d1 },
  d2: { ...d2 },
  d2_semiBold: { fontWeight: 600, ...d2 },
  d2_bold: { fontWeight: 700, ...d2 },
  d2_extraBold: { fontWeight: 800, ...d2 },
  h1: { ...h1 },
  h1_semiBold: { fontWeight: 600, ...h1 },
  h1_bold: { fontWeight: 700, ...h1 },
  h1_extraBold: { fontWeight: 800, ...h1 },
  h1s: { ...h1s },
  h1s_semiBold: { fontWeight: 600, ...h1s },
  h1s_bold: { fontWeight: 700, ...h1s },
  h1s_extraBold: { fontWeight: 800, ...h1s },
  h2: { ...h2 },
  h2_semiBold: { fontWeight: 600, ...h2 },
  h2_bold: { fontWeight: 700, ...h2 },
  h2_extraBold: { fontWeight: 800, ...h2 },
  h2s: { ...h2s },
  h2s_semiBold: { fontWeight: 600, ...h2s },
  h2s_bold: { fontWeight: 700, ...h2s },
  h2s_extraBold: { fontWeight: 800, ...h2s },
  h3: { ...h3 },
  h3_semiBold: { fontWeight: 600, ...h3 },
  h3_bold: { fontWeight: 700, ...h3 },
  h3_extraBold: { fontWeight: 800, ...h3 },
  h3s: { ...h3s },
  h3s_semiBold: { fontWeight: 600, ...h3s },
  h3s_bold: { fontWeight: 700, ...h3s },
  h3s_extraBold: { fontWeight: 800, ...h3s },
  h4: { ...h4 },
  h4_semiBold: { fontWeight: 600, ...h4 },
  h4_bold: { fontWeight: 700, ...h4 },
  h4_extraBold: { fontWeight: 800, ...h4 },
  h4s: { ...h4s },
  h4s_semiBold: { fontWeight: 600, ...h4s },
  h4s_bold: { fontWeight: 700, ...h4s },
  h4s_extraBold: { fontWeight: 800, ...h4s },
  h5: { ...h5 },
  h5_semiBold: { fontWeight: 600, ...h5 },
  h5_bold: { fontWeight: 700, ...h5 },
  h5_extraBold: { fontWeight: 800, ...h5 },
  h5s: { ...h5s },
  h5s_semiBold: { fontWeight: 600, ...h5s },
  h5s_bold: { fontWeight: 700, ...h5s },
  h5s_extraBold: { fontWeight: 800, ...h5s },
  h6: { ...h6 },
  h6_semiBold: { fontWeight: 600, ...h6 },
  h6_bold: { fontWeight: 700, ...h6 },
  h6_extraBold: { fontWeight: 800, ...h6 },
  h6s: { ...h6s },
  h6s_semiBold: { fontWeight: 600, ...h6s },
  h6s_bold: { fontWeight: 700, ...h6s },
  h6s_extraBold: { fontWeight: 800, ...h6s },
  subHeading: { ...subHeading },
  subHeading_semiBold: { fontWeight: 400, ...subHeading },
  subHeading_bold: { fontWeight: 600, ...subHeading },
  subHeading_extraBold: { fontWeight: 700, ...subHeading },
  p1: { ...p1 },
  p1_semiBold: { fontWeight: 400, ...p1 },
  p1_bold: { fontWeight: 600, ...p1 },
  p1_extraBold: { fontWeight: 700, ...p1 },
  p2: { ...p2 },
  p2_semiBold: { fontWeight: 400, ...p2 },
  p2_bold: { fontWeight: 600, ...p2 },
  p2_extraBold: { fontWeight: 700, ...p2 },
  p3: { ...p3 },
  p3_semiBold: { fontWeight: 400, ...p3 },
  p3_bold: { fontWeight: 600, ...p3 },
  p3_extraBold: { fontWeight: 700, ...p3 },
  caption: { ...caption },
  caption_semiBold: { fontWeight: 400, ...caption },
  caption_bold: { fontWeight: 600, ...caption },
  caption_extraBold: { fontWeight: 700, ...caption },
  footer: { ...footer },
  footer_semiBold: { fontWeight: 400, ...footer },
  footer_bold: { fontWeight: 600, ...footer },
  footer_extraBold: { fontWeight: 700, ...footer },

  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: "uppercase",
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "capitalize",
  },
};

export default typography;
