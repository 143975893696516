import { useEffect, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { PartitionsService } from "../../../../../api/services";
import DisableAnyway from "../../../../../components/DisableAnyway";
import BaseModal from "../../../../../components/BaseModal";

export default function EditActiveBookmakers({
  formData,
  successCallback,
  setMessage,
  setSnackbarStatus,
  setModalStatus,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [bookmakers, setBookmakers] = useState([]);
  const [activeBookmakers, setActiveBookmakers] = useState(
    formData.partition_bookmakers
      .filter((item) => item.is_active)
      .map((bookmaker) => bookmaker.bookmaker.id) || []
  );
  const [searchBookmakerQuery, setSearchBookmakerQuery] = useState("");
  const [selectAllBookmakers, setSelectAllBookmakers] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [openDisableAnyway, setOpenDisableAnyway] = useState(false);

  useEffect(() => {
    setBookmakers(
      formData.partition_bookmakers.map((bookmaker) => bookmaker.bookmaker)
    );
    setActiveBookmakers(
      formData.partition_bookmakers
        .filter((item) => item.is_active)
        .map((bookmaker) => bookmaker.bookmaker.id)
    );

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectAllBookmakers) {
      let allBookmakerIDs = bookmakers.map((bookmaker) => bookmaker.id);
      setActiveBookmakers(allBookmakerIDs);
    } else {
      if (activeBookmakers.length === bookmakers.length) {
        setActiveBookmakers([]);
      }
    }
    // eslint-disable-next-line
  }, [selectAllBookmakers]);

  useEffect(() => {
    if (
      activeBookmakers.length === bookmakers.length &&
      bookmakers.length !== 0
    ) {
      setSelectAllBookmakers(true);
    } else {
      setSelectAllBookmakers(false);
    }
    // eslint-disable-next-line
  }, [activeBookmakers]);

  const renderBookmakers = () => {
    return bookmakers
      .filter((bookmaker) =>
        bookmaker.name
          .toLowerCase()
          .includes(searchBookmakerQuery.toLowerCase())
      ).sort((a, b) => a.id - b.id)
      .map((bookmaker) => (
        <TableRow key={bookmaker.id}>
          <TableCell size="small">{bookmaker.name}</TableCell>
          <TableCell size="small" align="right" sx={{ paddingRight: "3em" }}>
            <Checkbox
              checked={activeBookmakers.includes(bookmaker.id)}
              onChange={() => handleBookmakerToggle(bookmaker)}
            />
          </TableCell>
        </TableRow>
      ));
  };

  const handleBookmakerToggle = (bookmaker) => {
    if (activeBookmakers.includes(bookmaker.id)) {
      setActiveBookmakers(
        activeBookmakers.filter((bookmakerID) => bookmakerID !== bookmaker.id)
      );
    } else {
      setActiveBookmakers([...activeBookmakers, bookmaker.id]);
    }
  };

  const handleSave = () => {
    setSubmitting(true);
    let payload = {
      active_bookmakers: activeBookmakers,
    };

    let succesMessage = t("active-bookmakers-update-succes");
    let failMessage = t("active-bookmakers-update-fail");

    PartitionsService.updatePartition(formData.id, payload)
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSubmitting(false);
          }, 600);
          setMessage(succesMessage);
          setSnackbarStatus(true);
          successCallback();
        } else {
          throw new Error("Update active bookmakers failed!");
        }
      })
      .catch((err) => {
        console.error(err);
        setTimeout(function () {
          if (
            err.response.data.detail ===
            "Failed to deactivate bookmaker. Bookmaker assigned to a sport!"
          ) {
            setOpenDisableAnyway(true);
            setSubmitting(false);
          } else {
            setMessage(failMessage);
            setSnackbarStatus(true);
            setSubmitting(false);

          }
        }, 800);
      });
  };

  const disableCallback = () => {
    //DisableAnyway Callback Function
  };

  return (
    <>
      <BaseModal
        title={t("disable-bookmaker")}
        open={openDisableAnyway}
        setOpen={setOpenDisableAnyway}
        children={
          <DisableAnyway
            successCallback={disableCallback}
            setModalStatus={setOpenDisableAnyway}
            setSnackbarStatus={setSnackbarStatus}
            setMessage={setMessage}
            apiService={() =>
              PartitionsService.updatePartition(
                formData.id,
                { active_bookmakers: activeBookmakers },
                {
                  proceed: true,
                }
              )
            }
            name={t("bookmaker")}
            usedIn={t("sport-bookmaker-mapping")}
          />
        }
      />
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 5,
          gap: 4,
          maxHeight: "70vh",
          overflow: "auto"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <TextField
            placeholder={t("search-bookmaker")}
            size="small"
            variant="standard"
            value={searchBookmakerQuery}
            onChange={(e) => setSearchBookmakerQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ border: "none" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("bookmaker-name")}</TableCell>
                <TableCell align="right">
                  {t("select")}{" "}
                  <Checkbox
                    style={{
                      transform: "scale(0.7)",
                      marginLeft: -10,
                    }}
                    checked={selectAllBookmakers}
                    onChange={(e) => setSelectAllBookmakers(e.target.checked)}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderBookmakers()}</TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            width: "20%",
            alignSelf: "center",
            display: "flex",
            gap: "10%",
            mb: -4
          }}
        >
          <Button
            sx={{
              backgroundColor: theme.palette.accent.main,
              height: "40px",
            }}
            size="large"
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={() => setModalStatus(false)}
          >
            {t("close")}
          </Button>
          <LoadingButton
            sx={{
              backgroundColor: theme.palette.success.main,
              height: "40px",
            }}
            size="large"
            variant="contained"
            onClick={handleSave}
            loading={isSubmitting}
            startIcon={<SaveIcon />}
          >
            {t("save")}
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
}
