import { useState, useEffect } from "react";
import { BaseTable} from "../../../../components/table";
import { Box, Switch} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { PartitionsService } from "../../../../api/services";
import BaseSnackbar from "../../../../components/BaseSnackbar";


const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: "3vh",
  backgroundColor: theme.palette.neutral.light,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

export default function ActiveSports({partition, selectedTab}) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [message, setMessage] = useState([]);
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const TABLE_HEAD = [
    { key: "sport", label: t("sport") },
    { key: "enabled", label: t("Enabled"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    id: {
      key: "id",
      cellComponentType: "th",
      index: 0,
      noRender: true,
    },
    sport: { key: "sport", index: 1 },
    is_active: { key: "enabled", index: 2 },
    sport_id: { key: "sport_id", index: 3, noRender: true },
  };

  const fetchSports = () => {
    if (partition && partition.sports) {
      setLoadingData(true);
      let partitionSportData = partition.sports.sort((a, b) => a.id - b.id);
      if (partitionSportData) {
        let items = [];
        for (const idx in partitionSportData) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = partitionSportData[idx];

          if (itemData.sport) {
            let sportName = itemData.sport?.name;
            item[TABLE_FIELD_MAPPING["sport"].index] = {
              ...TABLE_FIELD_MAPPING["sport"],
              value: sportName,
            };
            let sportID = itemData.sport?.id;
            item[TABLE_FIELD_MAPPING["sport_id"].index] = {
              ...TABLE_FIELD_MAPPING["sport_id"],
              value: sportID,
            };
          }

          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "sport") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          items.push(item);
        }
        setData(items);
        setLoadingData(false);
      } else {
        setData([]);
        setLoadingData(false);
      }
    }
  };

  useEffect(() => {
    if (partition && selectedTab === 0) {
      fetchSports();
    }
  }, [partition, selectedTab]);

  const handleOnChangeEnabled = (index) => {
    let activeSports = data
      .filter((row) => row.find((item) => item.key === "enabled" && item.value))
      .map((row) => row.find((item) => item.key === "sport_id").value);

    let successMessage = data[index][2].value
      ? t("sport-disabled-successfully")
      : t("sport-enabled-successfully");

    let failMessage = data[index][2].value
      ? t("sport-fail-disable")
      : t("fail-enable-sport");

    if (data[index][2].value) {
      // Active => deActive
      activeSports = activeSports.filter(
        (item) => item !== data[index][3].value
      );
    } else {
      // deActive => Active
      activeSports.push(data[index][3].value);
    }
    let payload = {
      active_sports: activeSports,
    };
    PartitionsService.updatePartition(partition.value, payload)
      .then((response) => {
        if (response.status === 200) {
          let newData = [...data];
          newData[index][2].value = !data[index][2].value;
          setData(newData);
          setMessage(successMessage);
          setSnackbarStatus(true);
        } else {
          throw new Error("Failed to deactivate sport!");
        }
      })
      .catch((err) => {
        setMessage(failMessage);
        setSnackbarStatus(true);
        console.error(err);
      });
  };

  const enableSwitch = (index) => {
    return (
      <Switch
        checked={data[index][2].value}
        onChange={() => handleOnChangeEnabled(index)}
      />
    );
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      
      {partition ? (
        <BaseTable
          head={TABLE_HEAD}
          data={data}
          pagination={{}}
          loadingData={loadingData}
          enableSwitch={enableSwitch}
          alignSwitch={"right"}
        />
      ) : (
        <StyledBox>Please select partition</StyledBox>
      )}
    </>
  );
}
