import { useRoutes, Navigate } from 'react-router-dom';
import { useStore } from './store/Store';
import MainLayout from './layouts/main';
import Auth from './Auth';
import WentWrong from './pages/WentWrong';
import { isTokenExpired } from './utils/Util';
import Page404 from './pages/Page404';
import LandingPage from './pages/partition/LandingPage';
import ListDistributors from './pages/partition/distributors/ListDistributors';
import AddDistributor from './pages/partition/distributors/addDistributor/addNewDistributor';
import ListPartitionUsers from './pages/partition/users/ListPartitionUsers';
import AddNewPartitionUser from './pages/partition/users/addPartitionUser/addPartitionUser';

export default function PartitionRouter() {
  const [store, dispatch] = useStore();
  const partition = JSON.parse(localStorage.getItem("partition"))

  const handleAppRedirect = () => {
    if (isTokenExpired(partition?.expires_at)) {
      (async () => {
        await dispatch({ type: "PARTITION_LOGOUT" });
      })();
    }

    if (partition !== null && store?.partition?.token !== null) {
        return <MainLayout isAdminLayout={true}/>;
    } else {
      return <Navigate to="/login" />;
    }
  };
  return useRoutes([
    {
      path: "/",
      element: handleAppRedirect(),
      children: [
        { path: "/", index: true, element: <Navigate to="/landing" replace /> },
        { path: "/landing", element: <LandingPage /> },
        { path: "/distributor", element: <Navigate to="/distributor/list" /> },
        { path: "/distributor/list", element: <ListDistributors /> },
        { path: "/distributor/add-new-distributor", element: <AddDistributor /> },
        { path: "/partition-users", element: <Navigate to="/partition-users/list-users" /> },
        { path: "/partition-users/list-users", element: <ListPartitionUsers /> },
        { path: "/partition-users/add-new-user", element: <AddNewPartitionUser /> },
      ]
    },
    {
      path: "/login",
      element: (partition === null || partition?.token === null ) ? <Auth type="partition-login" /> : <Navigate to="/"/>,
    },
    { path: "*", element: <Page404 /> },
    { path: "/oops", element: <WentWrong /> },
  ]);
}
