import React from 'react';
import { styled } from '@mui/material/styles';
import { Drawer } from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';

const DRAWER_WIDTH = 195;
const COLLAPSED_WIDTH = 0;

const DrawerStyle = styled(Drawer)(({ theme, open }) => ({
  width: open ? DRAWER_WIDTH : COLLAPSED_WIDTH,
  position: "absolute",
  zIndex: "1300",
  borderRadius: "6px",
  border: 0,
  overflowX: 'hidden',
  boxShadow: open ? theme.customShadows.dark : "none",
  backgroundColor: theme.palette.primary.lightmost, 
  '& .MuiDrawer-paper': {
    border: 0,
    width: open ? DRAWER_WIDTH : COLLAPSED_WIDTH,
    height: 'calc(100vh - 90px)',
    backgroundColor: theme.palette.primary.lightmost,
    boxShadow: open ? theme.customShadows.dark : "none",
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: COLLAPSED_WIDTH,
      width: open ? (DRAWER_WIDTH - COLLAPSED_WIDTH) : 0,
      height: '100%',
      backgroundColor: theme.palette.primary.lightmost,
    },
  },
}));

export default function HoverSidebar({ isOpen, navConfig, onMouseEnter, onMouseLeave, hoverNavTitle }) {
  return (
    <DrawerStyle
      variant="permanent"
      open={isOpen}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      
      <Scrollbar>
        <NavSection navConfig={navConfig} isOpen={isOpen} navTitle={hoverNavTitle} isHovered={true}/>
      </Scrollbar>

      
    </DrawerStyle>
  );
}
