import React, { useState, useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DistributorsService } from "../../../../api/services";
export default function EditDistributor({
  successCallback,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
}) {
  const { t } = useTranslation();
  const [name, setName] = useState("");

  useEffect(() => {
    if (formData) {
      setName(formData.name || "");
    }
  }, [formData]);

  const handleSubmit = (event) => {
    event.preventDefault(); 

    const updatedData = {
        ...formData,
        name: name, 
    };

    console.log("Submitting form data:", updatedData);
    DistributorsService.updateDistributor(updatedData.id, updatedData)
        .then((response) => {
            successCallback();
            setModalStatus(false);
            setSnackbarStatus(true);
            setMessage(t("distributor-updated-successfully"));
        })
        .catch((err) => {
            console.error("Update failed:", err);
            setSnackbarStatus(true);
            setMessage(t("failed-to-update-distributor"));
        });
};

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label={t("distributor-name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button onClick={() => setModalStatus(false)}>{t("cancel")}</Button>
          <Button type="submit" color="secondary">
            {t("save")}
          </Button>
        </Box>
      </Box>
    </form>
  );
}
