import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import MailIcon from "@mui/icons-material/Mail";
// material
import {
  Stack,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { LoadingButton } from "@mui/lab";
// app

import { useTranslation } from "react-i18next";
import BaseSnackbar from "../../BaseSnackbar";
import palette from "../../../theme/palette";
// ----------------------------------------------------------------------

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& input": {
    color: palette.neutral.lightmost,
    fontWeight: "bold",
    fontSize: "1.4rem",
    padding: "10px 0"
  },
  height: "10vh",
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomWidth: "4px",
    borderBottomColor: palette.accent.dark,
  },

  "& .MuiInput-underline:after": {
    borderBottomWidth: "4px",
    borderBottomColor: palette.accent.dark,
  },

  "& .MuiInputLabel-formControl": {
    fontWeight: "bold",
    marginTop: "1rem"
  },

  "& .MuiInputLabel-root": {
    transform: "translate(0, 0px) scale(1)",
    transition: "all 0.2s ease-out",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -23px) scale(1)",
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.92rem",
    color: palette.secondary.dark,
    fontWeight: "bold",
    marginTop: "8px",
  },
}));

export default function ForgotPasswordForm({forgotType}) {
  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [message, setMessage] = useState(null);

  const LoginSchema = Yup.object().shape({
    email: isSubmitted
      ? Yup.string()
          .email(t("email-must-be-valid"))
          .required(t("email-is-required"))
      : Yup.string().email(t("email-must-be-valid")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting, setTouched }) => {
      setIsSubmitted(true);
      setSubmitting(true);

      setTouched({
        email: true,
      });

      const payload = {
        email: values.email,
      };

      //TODO Handle submission here
      if(forgotType === "admin"){

        if (payload.email === "admin@mail") {
          setMessage("success");
          setOpenSnackbar(true);
        } else {
          setMessage(t("admin-email-not-found"));
          setOpenSnackbar(true);
        }

      }else if (forgotType === "store"){

        if (payload.email === "store@mail") {
          setMessage("success");
          setOpenSnackbar(true);
        } else {
          setMessage(t("store-email-not-found"));
          setOpenSnackbar(true);
        }

      }
    
      setSubmitting(false);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setOpenSnackbar}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <CustomTextField
              variant="standard"
              fullWidth
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomWidth: "4px",
                  borderBottomColor: getFieldProps("email").value
                    ? palette.accent.dark
                    : "white",
                },
              }}
              autoComplete="username"
              type="email"
              label={
                <Typography color={"primary.light"} variant="h4s" >
                  {t("email")}
                </Typography>
              }
              {...getFieldProps("email")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailIcon sx={{ mb: 1 }} />
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>
          <br />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{
              backgroundColor: palette.accent.dark,
              "&:hover": {
                backgroundColor: palette.accent.main, // Change to the color you want on hover
              },
              color: palette.accent.darkmost,
              fontWeight: "bold",
              fontSize: "1.2rem"
            }}
          >
            {t("send-reset-password-mail")}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
