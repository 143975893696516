import { useRoutes, Navigate } from 'react-router-dom';
import { useStore } from './store/Store';
import MainLayout from './layouts/main';
import Auth from './Auth';
import WentWrong from './pages/WentWrong';
import { isTokenExpired } from './utils/Util';
import Page404 from './pages/Page404';
import LandingPage from './pages/kiosk/LandingPage';

export default function KioskRouter() {
  const [store, dispatch] = useStore();
  const kiosk = JSON.parse(localStorage.getItem("kiosk"))

  const handleAppRedirect = () => {
    if (isTokenExpired(kiosk?.expires_at)) {
      (async () => {
        await dispatch({ type: "STORE_LOGOUT" });
      })();
    }

    if (kiosk !== null && store?.kiosk?.token !== null) {
        return <MainLayout isAdminLayout={true}/>;
    } else {
      return <Navigate to="/ki/login" />;
    }
  };
  return useRoutes([
    {
      path: "/ki",
      element:handleAppRedirect(),
      children: [
        { path: "/ki", index: true, element: <Navigate to="/ki/landing" replace /> },
        {path: "/ki/landing", element: <LandingPage/>},
      ]
    },
    {
      path: "/ki/login",
      element:(kiosk === null || kiosk?.token === null ) ? <Auth type="kiosk-login" /> : <Navigate to="/ki"/>,
    },
    { path: "*", element: <Page404 /> },
    { path: "/oops", element: <WentWrong /> },
  ]);
}
