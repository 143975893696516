import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../components/Logo";
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const MainStyle = styled("div")(({ theme }) => ({
  height: "70px",
  backgroundColor: theme.palette.primary.darker,
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  padding: "0 16px",
  justifyContent: "space-between" 
}));

export default function Header() {
  const { t } = useTranslation();
  return (
    <MainStyle>
      <Box
        component={RouterLink}
        to="/"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          marginLeft: "-15px"
        }} 
      >
        <Logo sx={{
          width: 150,
          height: 60
        }}/>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          component={RouterLink}
          to="/myaccount"
          variant="contained"
          color="primary"
          sx={{ marginRight: 2 }}
        >
          {t('My Account')}
        </Button>
        <Button
          component={RouterLink}
          to="/subscription"
          variant="contained"
          color="accent"
        >
          {t('Subscription')}
        </Button>
      </Box>
    </MainStyle>
  );
}
