import dayjs from "dayjs";


export const rowArrayToObject = (row) => {
  let obj = {};
  row.forEach((d, idx) => {
    obj[d.key] = d.value;
  });
  return obj;
};

export const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

/* export function isTokenExpired() {
  const expiresAt = localStorage.getItem("expires_at");
  const currentTime = Date.now() / 1000;
  return currentTime > parseInt(expiresAt);
} */

export function isTokenExpired(expiresAt) {
  const currentTime = Date.now() / 1000;
  return currentTime > parseInt(expiresAt);
}

export const formatDate = (dateString) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const dateFormatted = date.toLocaleDateString(undefined, options);
  const timeFormatted = date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return `${dateFormatted} ${timeFormatted}`;
};

export const formatDateInput = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS") : "";
};

export const isMobile = () => {
  return window.innerWidth <= 768;
};



export const fakeApiCall = ({ items, page, size }) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const start = (page - 1) * size;
      const end = start + size;
      const paginatedItems = items.slice(start, end);

      const fakeResponse = {
        data: {
          items: paginatedItems,
          total: items.length,
          page: page,
          size: size,
          pages: Math.ceil(items.length / size)
        }
      };

      resolve(fakeResponse);
    }, 600);
  });
};
