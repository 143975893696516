import PropTypes from "prop-types";
import { Box, Divider, List, Typography } from "@mui/material";
import NavItem from "./NavItem";
import { styled } from "@mui/material/styles";

NavSection.propTypes = {
  navConfig: PropTypes.array,
  isOpen: PropTypes.bool,
  navTitle: PropTypes.string,
  isHovered: PropTypes.bool,
};

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHovered',
})(({ theme, isHovered }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  marginTop: theme.spacing(-1),
  backgroundColor: isHovered
    ? theme.palette.accent.main_light
    : theme.palette.accent.main,
  color: theme.palette.accent.light,
}));

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isHovered',
})(({ theme, isHovered }) => ({
  color: isHovered ? theme.palette.neutral.darkest : theme.palette.neutral.darkest,
}));

export default function NavSection({
  navConfig,
  isOpen,
  navTitle,
  isHovered = false,
}) {

  return (
    <List >
      <StyledBox isHovered={isHovered}>
        <StyledTypography variant="h6" component="div" isHovered={isHovered}>
          {navTitle}
        </StyledTypography>
      </StyledBox>
      <Divider />
      {navConfig.map((item) => (
        <NavItem
          key={item.title}
          item={item}
          isOpen={isOpen}
          navConfig={navConfig}
          isHovered={isHovered}
        />
      ))}
    </List>
  );
}
