import React from 'react';
import { TextField, Box, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';


const SearchBarContainer = styled(Box)(({ theme }) => ({
  marginBottom:2,
  width: "30%",
}));


const SearchBar = ({ searchQuery, handleSearchChange, sx }) => {
  const { t } = useTranslation();

  return (
    <SearchBarContainer sx={sx} >
      <TextField
        placeholder={t("Search")}
        value={searchQuery}
        onChange={handleSearchChange}
        
        fullWidth
        InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{border:"none"}}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
      />
    </SearchBarContainer>
  );
};

export default SearchBar;