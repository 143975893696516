import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Box } from '@mui/material';


const BoxStyle = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.primary.lightmost,
    width: "100%",
    flexGrow: 1,
    borderRadius: 10,
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'flex-start',
    overflow: 'hidden',
    boxShadow: '2px 3px 4px 2px ' + theme.palette.grey[50032]
}));

const TableFilterContainer = ({ children, sx }) => (
    <BoxStyle sx={sx}>
        {children}
    </BoxStyle>
);

TableFilterContainer.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object, // Allow custom style to be passed
};

export default TableFilterContainer;