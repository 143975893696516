import PropTypes from 'prop-types';
// material
import { Button } from '@mui/material';
import palette from '../../theme/palette';
// ----------------------------------------------------------------------

PrimaryButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    StartIcon: PropTypes.object,
    EndIcon: PropTypes.object,
    sx: PropTypes.object,
    color: PropTypes.string,
    props: PropTypes.object,
};

//TODO compare with figma handle details

export default function PrimaryButton({ label, onClick, StartIcon, EndIcon, sx, color = "primary", props = {} }) {

    const textColor = palette.primary.lightmost;

    return (
        <Button
            variant="contained"
            color={color}
            onClick={onClick ? onClick : null}
            sx={{ color: textColor, ...sx }}
            startIcon={StartIcon ? <StartIcon /> : null}
            endIcon={EndIcon ? <EndIcon /> : null}
            {...props}
        >{label}
        </Button>
    );
}
