import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isHovered'
})(({ theme, isActive, isHovered }) => ({
  height: 48,
  position: "relative",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  color: theme.palette.secondary.contrastText,
  backgroundColor: isActive ? (isHovered ? theme.palette.neutral.lightmost : theme.palette.neutral.lightmost) : "inherit",
  borderRadius: "100px",
  '&:hover': {
    backgroundColor: theme.palette.neutral.main
  },
  marginTop: 10,
}));

const ListItemIconStyle = styled(ListItemIcon, {
  shouldForwardProp :(prop) => prop !== 'isActive'
})(({ theme, isActive}) => ({
  color: isActive ? theme.palette.accent.darker : theme.palette.neutral.dark,
  "& svg": {
    fontSize: "18px",
  }
}))

const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) =>  prop !== 'isActive'
})(({ theme, isActive }) => ({
  color: isActive ? theme.palette.neutral.darkest : theme.palette.neutral.darkest,
  '& .MuiListItemText-primary': { 
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 800,
  },
}));

const isPartialPathMatch = (configPath, currentPath) => {
  const configSegments = configPath.split("/");
  const currentSegments = currentPath.split("/");

  if (configSegments.length > currentSegments.length) {
    return false;
  }

  return configSegments.every((segment, index) => segment === currentSegments[index]);
};

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  isHovered: PropTypes.bool
};

export default function NavItem({ item, isOpen, isHovered }) {
  const { title, path, icon, children } = item;
  const [open, setOpen] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const prevIsOpenRef = useRef(isOpen);
  const [isActive, setIsActive ] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsActive(isPartialPathMatch(path, location.pathname));
  }, [location.pathname, path]);

  useEffect(() => {
    if (!prevIsOpenRef.current && isOpen) {
      const timer = setTimeout(() => {
        setShowTitle(true);
      }, 190);
      return () => clearTimeout(timer);
    } else {
      setShowTitle(isOpen);
    }
    prevIsOpenRef.current = isOpen;
  }, [isOpen]);

  const handleClick = () => {
    if (isOpen) {
      setOpen((prev) => !prev);
    }
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleClick}
          component={!isOpen ? RouterLink : "div"}
          to={!isOpen ? path : undefined}
          isActive={isActive}
          isHovered= {isHovered}
        >
          <ListItemIconStyle isActive={isActive}>{icon}</ListItemIconStyle>
          {isOpen && showTitle && <ListItemTextStyle primary={title} />}
          {isOpen ? open ? <ExpandLess /> : <ExpandMore /> : ""}
        </ListItemStyle>
        {isOpen && (
          
          <Collapse in={open} timeout="auto" unmountOnExit>
              

            <Box sx={{ pl: 0 }}>
            <Divider
               sx={{mt: 1}}/>
              {children.map((child) => (
                <NavItem key={child.title} item={child} isOpen={isOpen} />
              ))}
            </Box>
            <Divider/>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle component={RouterLink} to={path} isActive={isActive} isHovered={isHovered}>
      <ListItemIconStyle isActive={isActive}>{icon}</ListItemIconStyle>
      {isOpen && showTitle && <ListItemTextStyle primary={title} isActive={isActive} />}
    </ListItemStyle>
  );
}
