import { useEffect, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { PartitionsService } from "../../../../../api/services";

export default function EditActiveSports({
  formData,
  successCallback,
  setMessage,
  setSnackbarStatus,
  setModalStatus
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [sports, setSports] = useState([]);
  const [activeSports, setActiveSports] = useState(formData.partition_sports
    .filter((item) => item.is_active)
    .map((sport) => sport.sport.id) || []);
  const [searchSportQuery, setSearchSportQuery] = useState("");
  const [selectAllSports, setSelectAllSports] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);


  useEffect(() => {
    setSports(formData.partition_sports.map((sport) => sport.sport))
    setActiveSports(formData.partition_sports
      .filter((item) => item.is_active)
      .map((sport) => sport.sport.id))

      //eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (selectAllSports) {
      let allSportIds = sports.map((sport) => sport.id);
      setActiveSports(allSportIds);
    } else {
      if (activeSports.length === sports.length) {
        setActiveSports([]);
      }
    }
    // eslint-disable-next-line
  }, [selectAllSports]);

  useEffect(() => {
    if (activeSports.length === sports.length && sports.length !== 0) {
      setSelectAllSports(true);
    } else {
      setSelectAllSports(false);
    }
    // eslint-disable-next-line
  }, [activeSports]);

  const renderSports = () => {
    return sports
      .filter((sport) =>
        sport.name.toLowerCase().includes(searchSportQuery.toLowerCase())
      ).sort((a, b) => a.id - b.id)
      .map((sport) => (
        <TableRow key={sport.id}>
          <TableCell size="small">{sport.name}</TableCell>
          <TableCell size="small" align="right" sx={{ paddingRight: "3em" }}>
            <Checkbox
              checked={activeSports.includes(sport.id)}
              onChange={() => handleSportToggle(sport)}
            />
          </TableCell>
        </TableRow>
      ));
  };

  const handleSportToggle = (sport) => {
    if (activeSports.includes(sport.id)) {
      setActiveSports(activeSports.filter((sportID) => sportID !== sport.id));
    } else {
      setActiveSports([...activeSports, sport.id]);
    }
  };

  const handleSave = () => {

    setSubmitting(true);
    let payload = {
      active_sports: activeSports
    }

    let succesMessage = t("active-sports-update-succes");
    let failMessage = t("active-sports-update-fail");

    PartitionsService.updatePartition(formData.id, payload)
      .then((response) => {

        if(response.status === 200){
          
          setTimeout(() => {
            setSubmitting(false);
          }, 600)
          setMessage(succesMessage);
          setSnackbarStatus(true);
          successCallback();


        }else {
          throw new Error("Update active sports failed!")
        }

      })
      .catch((err) => {
        console.error(err);
        setTimeout(() => {
          setSubmitting(false);
        }, 600)
        setMessage(failMessage);
        setSnackbarStatus(true);
      })

  };

  return (
    <>     
        <Card sx={{
          display: "flex",
          flexDirection: "column",
          padding: 5,
          gap: 4,
          maxHeight: "70vh",
          overflow: "auto"
        }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <TextField
              placeholder={t("search-sport")}
              size="small"
              variant="standard"
              value={searchSportQuery}
              onChange={(e) => setSearchSportQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ border: "none" }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("sport-name")}</TableCell>
                  <TableCell align="right">
                    {t("select")}{" "}
                    <Checkbox
                      style={{
                        transform: "scale(0.7)",
                        marginLeft: -10,
                      }}
                      checked={selectAllSports}
                      onChange={(e) => setSelectAllSports(e.target.checked)}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderSports()}</TableBody>
            </Table>
          </TableContainer>

          <Box
             sx={{
              width: "20%",
              alignSelf: "center",
              display: "flex",
              gap: "10%",
              mb: -4
            }}
          >
            <Button
              sx={{
                backgroundColor: theme.palette.accent.main,
                height: "40px",
              }}
              size="large"
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={() => setModalStatus(false)}
            >
              {t("close")}
            </Button>
            <LoadingButton
              sx={{
                backgroundColor: theme.palette.success.main,
                height: "40px",
              }}
              size="large"
              variant="contained"
              onClick={handleSave}
              loading={isSubmitting}
              startIcon={<SaveIcon />}
            >
              {t("save")}
            </LoadingButton>
          </Box>
        </Card>

    </>
  );
}
