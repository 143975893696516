import api from "../Api";
import urls from "../Urls";

//PARTITION USERS

export const listPartitionUsers = (params) => {
    return api.get(urls.list_partitions_users, { params: params });
};

export const getPartitionUsers = (id) => {
    return api.get(urls.get_partition_users(id))
};

export const createPartitionUsers = (payload) => {
    return api.post(urls.create_partition_users, payload)
}

export const updatePartitionUsers = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_partition_users(id), payload, {params})
}

export const updatePartitionUsersPassword = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_partition_users_password(id), payload, {params})
}

export const deletePartitionUsers = (id) => {
    return api.delete(urls.delete_partition_users(id));
};

//DISTRIBUTOR USERS

export const listDistributorUsers = (params) => {
    return api.get(urls.list_distributor_users, { params: params });
};

export const getDistributorUsers = (id) => {
    return api.get(urls.get_distributor_users(id))
};

export const createDistributorUsers = (payload) => {
    return api.post(urls.create_distributor_users, payload)
}

export const updateDistributorUsers = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_distributor_users(id), payload, {params})
}

export const updateDistributorUsersPassword = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_distributor_users_password(id), payload, {params})
}

export const deleteDistributorUsers = (id) => {
    return api.delete(urls.delete_distributor_users(id));
};

//STORE USERS

export const listStoreUsers = (params) => {
    return api.get(urls.list_store_users, { params: params });
};

export const getStoreUsers = (id) => {
    return api.get(urls.get_store_users(id))
};

export const createStoreUsers = (payload) => {
    return api.post(urls.create_store_users, payload)
}

export const updateStoreUsers = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_store_users(id), payload, {params})
}

export const updateStoreUsersPassword = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_store_users_password(id), payload, {params})
}

export const deleteStoreUsers = (id) => {
    return api.delete(urls.delete_store_users(id));
};