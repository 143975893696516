import { useState } from "react";
import { styled, Tabs, Tab, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditGeneral from "./editTabs/EditGeneral";
import EditActiveSports from "./editTabs/EditActiveSports";
import EditActiveBoookmakers from "./editTabs/EditActiveBoookmakers";
import EditSportsBookmapping from "./editTabs/EditSportsBookmapping";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

const TabStyle = styled(Tab)(({ theme }) => ({
  padding: "0 10px",
  minHeight: "25px",
  "&:hover": {
    color: theme.palette.neutral.main,
  },
  marginLeft: 8,
  "&.Mui-selected": {
    color: theme.palette.neutral.main_dark,
    fontSize: "15px",
    backgroundColor: theme.palette.neutral.light,
  },
}));

export default function EditPartition({
  successCallback,
  formData,
  setModalStatus,
  setSnackbarStatus,
  setMessage,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const tabs = [
    {
      id: 0,
      label: t("edit-general"),
      component: (
        <EditGeneral
          formData={formData}
          setMessage={setMessage}
          setSnackbarStatus={setSnackbarStatus}
          successCallback={successCallback}
          setModalStatus={setModalStatus}
        />
      ),
    },
    {
      id: 1,
      label: t("edit-active-sports"),
      component: (
        <EditActiveSports
          formData={formData}
          setMessage={setMessage}
          setSnackbarStatus={setSnackbarStatus}
          successCallback={successCallback}
          setModalStatus={setModalStatus}
        />
      ),
    },
    {
      id: 2,
      label: t("edit-active-bookmakers"),
      component: (
        <EditActiveBoookmakers
          formData={formData}
          setMessage={setMessage}
          setSnackbarStatus={setSnackbarStatus}
          successCallback={successCallback}
          setModalStatus={setModalStatus}
        />
      ),
    },
    {
      id: 3,
      label: t("edit-sportsbook-mapping"),
      component: (
        <EditSportsBookmapping
          formData={formData}
          setMessage={setMessage}
          setSnackbarStatus={setSnackbarStatus}
          successCallback={successCallback}
          setModalStatus={setModalStatus}
        />
      ),
    },
  ];

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        sx={{
          ml: 3,
          "& .MuiTabs-indicator": {
            bottom: "15px",
            backgroundColor: theme.palette.neutral.dark,
          },
        }}
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="nav tabs"
      >
        {tabs.map((item) => (
          <TabStyle
            key={item.id}
            label={item.label}
            sx={{ color: theme.palette.neutral.darker }}
          />
        ))}
      </Tabs>
      <div
        role="tabpanel"
        id={`tabpanel-${tabValue}`}
        aria-labelledby={`tab-${tabValue}`}
      >
        <Box sx={{ pl: 3, flexGrow: 1 }}>{tabs[tabValue].component}</Box>
      </div>
      {/* <Box>
        close
      </Box> */}
    </Box>
  );
}
