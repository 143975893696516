import api from "../Api";
import urls from "../Urls";

export const listDistributors = (params) => {
    return api.get(urls.list_distributors, { params: params });
};

export const getDistributor = (id) => {
    return api.get(urls.get_distributor(id))
};

export const createDistributor = (payload) => {
    return api.post(urls.create_distributor, payload)
}


export const updateDistributor = (id, payload,  params ) => {
    params = params ? params : {proceed : false};
    return api.put(urls.update_distributor(id), payload, {params})
}

export const deleteDistributor = (id) => {
    return api.delete(urls.delete_distributor(id));
};